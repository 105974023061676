

// Colors
@import 'colors';



// Home - Banner -----------------------------------------------------------------------

section.banner-home {
    background: linear-gradient(145deg, $primary 35%, $secondary 100%);
    padding: 20px 0;
    color: #fff;
}
section.banner-home .phrases .title {
    font-size: 36px;
    font-weight: 600;
}
section.banner-home .phrases .subtitle {
    font-size: 16px;
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
}
section.banner-home .btn-primary {
    background-color: $green;
    border: 0;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 16px;
}
section.banner-home img {
    max-width: 450px;
}




// Home - Soluções -----------------------------------------------------------------------

section.solutions-home {
    padding: 60px 0;
}
section.solutions-home .title {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
}
section.solutions-home .description {
    text-align: center;
    font-size: 15px;
    max-width: 80%;
    margin: auto;
    color: #888;
}
section.solutions-home .solution-block .solution-image {
    text-align: right;
}
section.solutions-home .solution-block .solution-itens a {
    border: 1px #ccc dotted;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    clear: both;
    line-height: 50px;
    color: #666;
    font-weight: bold;
    transition: all 0.2s ease-in-out 0s;
}
section.solutions-home .solution-block .solution-itens a:hover {
    border: 1px $primary dotted;
    background-color: $primary;
    color: #fff;
}


// Home - Segmentos -----------------------------------------------------------------------

section.segments-home a {
    color: #666;
    display: block;
    text-decoration: none;
    transition: all 0.2s ease-in-out 0s;
}
section.segments-home a:hover {
    color: #000;
    text-decoration: underline;
}
section.segments-home a img {
    opacity: 0.5;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out 0s;
}
section.segments-home a:hover img {
    opacity: 0.8;
    margin-bottom: 5px;
}




// Home - Depoimentos -----------------------------------------------------------------------

section.testimonials-home {
    color: #fff;
    font-size: 14px;
    margin: 40px 0;
}
section.testimonials-home .bg {
    padding: 60px 0;
    background: linear-gradient(145deg, $primary 35%, $secondary 100%);
    border-radius: 10px;
}
section.testimonials-home .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 28px;
}
section.testimonials-home .name {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}
section.testimonials-home .icon {
    font-size: 30px;
}


// Home - Beneficios -----------------------------------------------------------------------

section.benefits-home {
    padding: 40px 0;
    font-weight: bold;
}


// Home - Resultados -----------------------------------------------------------------------

section.results-home {
    font-size: 14px;
}
section.results-home h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 26px;
}
section.results-home .description {
    max-width: 400px;
    margin: auto;
}
section.results-home .description .icon {
    font-size: 30px;
}
section.results-home .bg {
    border: 1px #ccc dotted;
    padding: 60px;
    border-radius: 10px;
}
section.results-home .progress {
    margin-bottom: 10px;
}
section.results-home .progress-bar {
    background: #ccc;
    border-radius: 20px;
    margin-right: 10px;
}
section.results-home .progress-color {
    background: $green;
}



// Home - Aplicações -----------------------------------------------------------------------

section.applications-home {
    margin: 40px 0;
}
section.applications-home a {
    display: block;
    padding: 20px 0;
    border: 1px #ccc dotted;
    height: 100%;
    color: $primary;
    border-radius: 5px;
    transition: all 0.2s ease-in-out 0s;
}
section.applications-home a:hover {
    background-color: rgba($color: #ffffff, $alpha: 0.4);
}


// Home - Blog -----------------------------------------------------------------------

section.blog-home {
    padding: 40px 0;
}
section.blog-home h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px;
}



// Solution -----------------------------------------------------------------------

section.solution h1 {
    font-size: 30px;
    font-weight: 600;
}
section.solution .image img {
    max-width: 350px;
}
section.solution .description .text {
    padding: 20px 0;
    color: #333;
}
section.solution .description h3 {
    font-size: 20px;
    font-weight: 600;
}
section.solution .description .form a {
    color: $pink;
}
section.solution .description .form input, section.solution .description .form textarea {
    border-radius: 3px;
}
section.solution .description .form .btn-primary {
    background-color: $pink;
    border-color: $pink;
    border-radius: 50px;
}


// Contact -----------------------------------------------------------------------

section.contact h1 {
    font-size: 30px;
    font-weight: 600;
}
section.contact .image img {
    max-width: 350px;
}
section.contact .description .text {
    padding: 20px 0;
    color: #333;
}
section.contact .description h3 {
    font-size: 20px;
    font-weight: 600;
}
section.contact .form a {
    color: $pink;
}
section.contact .form input, section.contact .form textarea {
    border-radius: 3px;
}
section.contact .form .btn-primary {
    background-color: $pink;
    border-color: $pink;
    border-radius: 50px;
}






// Mobile 

@media (max-width: 576px) {


    // Home - Banner -----------------------------------------------------------------------

    section.banner-home {
        padding: 30px 0;
        text-align: center;
    }
    section.banner-home .phrases .title {
        font-size: 26px;
    }
    section.banner-home .btn-primary {
        margin-bottom: 30px;
    }
    section.banner-home img {
        width: 100%;
    }

    // Home - Soluções -----------------------------------------------------------------------

    section.solutions-home .solution-block .solution-image {
        text-align: center;
    }
    section.solutions-home .solution-block .solution-itens {
        padding-top: 30px;
        text-align: center;
    }
    section.solutions-home .solution-block .solution-itens a {
        font-size: 16px;
    }


    // Home - Result -----------------------------------------------------------------------

    section.results-home .graphics {
        padding-top: 30px;
    }
    

    // Home - Aplicações -----------------------------------------------------------------------

    section.applications-home {
        margin: 20px 0;
    }
    section.applications-home a {
        margin-bottom: 10px;
        font-size: 16px;
    }

    // Home - Blog -----------------------------------------------------------------------

    section.blog-home .item-post {
        margin-bottom: 20px;
    }

    // Solution -----------------------------------------------------------------------

    section.solution h1 {
        margin-top: 20px;
    }
    section.solution .image img {
        width: 50%;
    }
    section.solution .description .form .row {
        margin-bottom: 0;
    }
    section.solution .description .form .row > div {
        margin-bottom: 10px;
    }
    section.solution .description .form .btn-primary {
        width: 100%;
    }
        
    // Contact -----------------------------------------------------------------------

    section.contact .form .row {
        margin-bottom: 0;
    }
    section.contact .form .row > div {
        margin-bottom: 10px;
    }
    section.contact .form .btn-primary {
        width: 100%;
    }


}